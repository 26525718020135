<template>
    <div class="survey-view">
        <RouterView />
    </div>
</template>

<script>
export default {
    name: 'Survey'
}
</script>

<style scope lang="scss">
.survey-view {
}
</style>
